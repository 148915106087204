
import { useRoute, useRouter } from 'vue-router'
import request from '@/utils/request'

import { defineComponent } from 'vue'
import { setToken } from '@/utils/auth'
export default defineComponent({
	async setup() {
		const route = useRoute()
		const { code } = route.query || ''
		const userLogin = async (code: any) => {
			const { data } = await request
				.post('/v1/merchants/user/login', { code })
				.catch(() => {
					setTimeout(() => {
						location.href = '/user/login'
					}, 1000)
					return { data: null }
				})
			if (data) {
				setToken(data.token)
				location.replace('/')
			}

			return true
		}
		if (code) {
			await userLogin(code)
		}
	},
})
