import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_z_button = _resolveComponent("z-button")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_z_page = _resolveComponent("z-page")!

  return (_openBlock(), _createBlock(_component_z_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_z_button, null, {
          default: _withCtx(() => [
            _createTextVNode("test")
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_button, null, {
          default: _withCtx(() => [
            _createTextVNode("test")
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }))
}